import React from 'react';
import { PlaceholderLoader } from '@hotelplan/components.common.placeholder-loader';

const GreetingsSkeleton: React.FC = () => {
  const width = 240;
  const height = 30;
  return (
    <PlaceholderLoader
      uid="book-card"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      style={{
        height: height,
        width: width,
        marginBottom: '16px',
      }}
    >
      <>
        <rect width={width} height={height} rx="4" />
      </>
    </PlaceholderLoader>
  );
};

export default GreetingsSkeleton;
