import styled from 'styled-components';
import { Button } from '@hotelplan/components.common.buttons';
import { Icon } from '@hotelplan/components.common.icon';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { Link } from '@hotelplan/components.common.link';

const lightGreyFirst = '#cccccc';
const lightGreySecond = '#EBEBEB';
const lightGreyBackground = '#f5f5f5';
const borderGrey = '#BFBFBF';

export const MyAccountHeaderButtonWrapper = styled.div<{
  isAuthenticated: boolean;
}>(
  sx2CssThemeFn({
    cursor: 'pointer',
    '&:not(:last-child)': {
      borderRight: '1px solid',
      borderColor: lightGreyFirst,
    },
  })
);

export const MyAccountButton = styled(Button)<{
  isAuthenticated: boolean;
}>(({ isAuthenticated }) =>
  sx2CssThemeFn({
    height: '30px',
    width: !isAuthenticated ? '110px' : '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    px: 2,
    gap: 2,
    color: 'black',
    border: '1px solid',
    borderRadius: isAuthenticated ? '50%' : '5px',
    borderColor: isAuthenticated ? 'borderColor' : 'black',
    fontSize: '14px',
    letterSpacing: '0px',
    bg: 'white',
    p: '6px',

    '&:hover': {
      bg: isAuthenticated ? 'transparent' : 'black',
      '.icon': {
        color: isAuthenticated ? 'primary' : 'white',
      },
    },
    ':hover:not([disabled]),:focus-visible:not([disabled]),:focus:not([disabled])': {
      border: '1px solid',
      backgroundColor: isAuthenticated ? 'transparent' : 'black',
      borderColor: isAuthenticated ? 'borderColor' : 'black',
      '.icon': {
        color: isAuthenticated ? 'primary' : 'white',
      },
    },
  })
);

export const MyAccountIcon = styled(Icon)(
  sx2CssThemeFn({
    color: 'black',
    zIndex: 1,
    width: '100%',
  })
);

export const MyAccountHeaderMenuWrapper = styled.div(
  sx2CssThemeFn({
    position: 'absolute',
    width: ['100%', `max-content`],
    top: ['100%', '100%'],
    right: [0, '-1px'],
    py: ['14px', 3],
    px: ['14px', 4],
    bg: 'white',
    border: ['none', '1px solid'],
    borderTop: '1px solid',
    borderColor: [lightGreyFirst, lightGreyFirst],
    borderRadius: ['0px', '0 0 10px 10px'],
    cursor: 'default',
    zIndex: 1000,
    height: ['100vh', 'auto'],
    '.greetings': {
      fontSize: ['20px', '22px'],
      px: ['12px', 0],
    },
  })
);

export const MenuItemsWrapper = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: ['flex-start', 'space-between'],
    width: '100%',
    borderTop: '1px solid',
    borderBottom: '1px solid',
    borderColor: [borderGrey, lightGreySecond],
    pt: ['0px', '10px'],
    mt: '12px',
    '.menu-item': {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      py: '10px',
      px: ['12px', 0],
      cursor: 'pointer',
      fontFamily: '"Helvetica Neue", sans-serif',
      fontSize: '17px',
      '&:hover': {
        bg: lightGreyBackground,
        color: 'interactionPrimary',
      },
      '&:not(:last-child)': {
        borderBottom: ['1px solid', 'none'],
        borderColor: borderGrey,
      },
    },
  })
);

const AccountLink = styled(Link)<{ active?: boolean }>(
  ({ theme: { FONT_SIZE } }) =>
    sx2CssThemeFn({
      display: 'flex',
      justifyContent: 'space-between',
      ...FONT_SIZE.LINK_LARGE,
      textAlign: 'left',
      width: ['100%', 'auto'],
      p: [2, 0],
      borderTop: ['1px solid', 'none'],
      borderBottom: ['1px solid', 'none'],
      borderColor: 'borderColor',
      mb: ['-1px', 0],
      borderRadius: 0,
    }),
  ({ active, theme: { colors } }) => ({
    color: active ? colors.primary : undefined,
  })
);

const LogoutButton = AccountLink.withComponent(
  styled(Button)(
    ({ theme }) => theme.buttons.linkBtn,
    sx2CssThemeFn({
      color: 'defaultText',
      fontWeight: 'normal',
      flexDirection: 'row-reverse',
      gap: 2,
    })
  )
);

export const MenuLogoutLink = styled(LogoutButton)(
  sx2CssThemeFn({
    fontSize: '17px',
    justifyContent: 'start',
    width: '100%',
    letterSpacing: '0px',
    px: ['12px', '0px'],
    py: '10px',
    border: 'none',
  })
);
