import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';

export const MyAccountHeaderSectionWrapper = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    alignItems: 'center',
    height: [null, '64px'],
    px: [null, 3],
    borderRight: [null, '1px solid'],
    borderColor: [null, 'borderColor'],
    mx: [3, 0],
    position: ['static', 'relative'],
  })
);
