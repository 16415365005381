import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { useMemo, useRef } from 'react';
import { SSR_MY_ACCOUNT_LOGIN_ENDPOINT_PATH } from '@hotelplan/libs.auth-next';
import { useOnClickOutside } from '@hotelplan/libs.hooks-dom';
import {
  MyAccountButton,
  MyAccountHeaderButtonWrapper,
  MyAccountIcon,
} from './MyAccountHeader.styles';
import MyAccountHeaderMenu from './MyAccountHeaderMenu';

interface IMyAccountHeaderButtonProps {
  isAuthenticated: boolean;
  isMenuOpened: boolean;
  toggleMenu?(): void;
  closeMenu?(): void;
}

const authIcon = {
  de: `mein-konto-de`,
  fr: `mein-konto-fr`,
};

export default function MyAccountHeaderButton({
  isAuthenticated,
  isMenuOpened,
  toggleMenu,
  closeMenu,
}: IMyAccountHeaderButtonProps): React.ReactElement {
  const { push } = useRouter();
  const [, { language }] = useTranslation('myaccount');

  const iconName = useMemo(
    () => (isAuthenticated ? `person` : authIcon[language]),
    [language, isAuthenticated]
  );

  function handleMyAccountButtonClick() {
    const loginPath = `${SSR_MY_ACCOUNT_LOGIN_ENDPOINT_PATH}?lang=${language}`;
    isAuthenticated ? toggleMenu() : push(loginPath);
  }

  const myAccountHeaderButtonRef = useRef<HTMLButtonElement>(null);
  const myAccountHeaderMenuItemsRef = useRef<HTMLDivElement>(null);

  useOnClickOutside<HTMLDivElement | HTMLButtonElement>(
    [myAccountHeaderMenuItemsRef, myAccountHeaderButtonRef],
    () => {
      if (!isMenuOpened) return;
      closeMenu();
    }
  );

  return (
    <MyAccountHeaderButtonWrapper isAuthenticated={isAuthenticated}>
      <MyAccountButton
        isAuthenticated={isAuthenticated}
        onClick={handleMyAccountButtonClick}
        ref={myAccountHeaderButtonRef}
      >
        <MyAccountIcon name={iconName} />
      </MyAccountButton>

      <div ref={myAccountHeaderMenuItemsRef}>
        {isMenuOpened && (
          <MyAccountHeaderMenu
            isOpened={isMenuOpened}
            onMenuItemClick={closeMenu}
          />
        )}
      </div>
    </MyAccountHeaderButtonWrapper>
  );
}
