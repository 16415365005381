import type { CSSObject } from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';

export const siteToolsButtonCss = sx2CssThemeFn({
  color: '#000',
  '&:hover': {
    color: 'interactionPrimary',
    'svg.icon': {
      color: 'interactionPrimary',
    },
  },
  '.icon:hover': {
    color: 'interactionPrimary',
  },
  '@media (hover: none)': {
    '&:hover': {
      'svg.icon': {
        color: 'defaultText',
      },
    },
  },
  '.icon': {
    width: '22px',
    height: '22px',
  },
  pl: 1,
  paddingRight: 1,
  position: 'relative',
});

export const helpOverlayButtonActiveCss: CSSObject = {
  '&:after': {
    content: "''",
    width: '0',
    height: '0',
    borderStyle: 'solid',
    borderWidth: '0 12px 14px 12px',
    borderColor: 'transparent transparent #EBEFF0 transparent',
    position: 'absolute',
    bottom: '-21px',
    left: '50%',
    transform: 'translateX(-50%)',
  },
};
