import type * as Types from 'types/types';

import type { DocumentNode } from 'graphql';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type GetAccountPersonalDataQueryVariables = Types.Exact<{
  context: Types.RequestContextInput;
}>;

export type GetAccountPersonalDataQuery = {
  __typename?: 'Query';
  account: {
    __typename?: 'AccountContext';
    personalData: {
      __typename?: 'AccountPersonalDataResponse';
      firstName?: string | null;
      lastName?: string | null;
      salutation?: Types.Salutation | null;
      mobileDetails?: {
        __typename?: 'PhoneDetails';
        country?: string | null;
        phone?: string | null;
      } | null;
      phoneDetails?: {
        __typename?: 'PhoneDetails';
        country?: string | null;
        phone?: string | null;
      } | null;
    };
  };
};

export type PersonalDataFragment = {
  __typename?: 'AccountPersonalDataResponse';
  firstName?: string | null;
  lastName?: string | null;
  salutation?: Types.Salutation | null;
  mobileDetails?: {
    __typename?: 'PhoneDetails';
    country?: string | null;
    phone?: string | null;
  } | null;
  phoneDetails?: {
    __typename?: 'PhoneDetails';
    country?: string | null;
    phone?: string | null;
  } | null;
};

export type PhoneDetailsFragment = {
  __typename?: 'PhoneDetails';
  country?: string | null;
  phone?: string | null;
};

export const PhoneDetailsFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'phoneDetails' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PhoneDetails' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode;
export const PersonalDataFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'personalData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AccountPersonalDataResponse' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salutation' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mobileDetails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'phoneDetails' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phoneDetails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'phoneDetails' },
                },
              ],
            },
          },
        ],
      },
    },
    ...PhoneDetailsFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
export const GetAccountPersonalDataDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAccountPersonalData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RequestContextInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'account' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'personalData' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'personalData' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...PersonalDataFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;

/**
 * __useGetAccountPersonalDataQuery__
 *
 * To run a query within a React component, call `useGetAccountPersonalDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountPersonalDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountPersonalDataQuery({
 *   variables: {
 *      context: // value for 'context'
 *   },
 * });
 */
export function useGetAccountPersonalDataQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetAccountPersonalDataQuery,
    GetAccountPersonalDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetAccountPersonalDataQuery,
    GetAccountPersonalDataQueryVariables
  >(GetAccountPersonalDataDocument, options);
}
export function useGetAccountPersonalDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetAccountPersonalDataQuery,
    GetAccountPersonalDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetAccountPersonalDataQuery,
    GetAccountPersonalDataQueryVariables
  >(GetAccountPersonalDataDocument, options);
}
export type GetAccountPersonalDataQueryHookResult = ReturnType<
  typeof useGetAccountPersonalDataQuery
>;
export type GetAccountPersonalDataLazyQueryHookResult = ReturnType<
  typeof useGetAccountPersonalDataLazyQuery
>;
export type GetAccountPersonalDataQueryResult = Apollo.QueryResult<
  GetAccountPersonalDataQuery,
  GetAccountPersonalDataQueryVariables
>;
