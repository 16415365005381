import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { useRef } from 'react';
import { useAuthentication } from '@hotelplan/components.common.auth';
import { Greetings } from '@hotelplan/components.common.greetings';
import { Link } from '@hotelplan/components.common.link';
import {
  IRouterLinkProps,
  RouterLink,
} from '@hotelplan/components.common.router-link';
import { PageType } from '@hotelplan/graphql.types';
import { SSR_MY_ACCOUNT_LOGOUT_ENDPOINT_PATH } from '@hotelplan/libs.auth-next';
import { useScrollElement } from '@hotelplan/libs.context.ios-scroll-block';
import { usePageType } from '@hotelplan/libs.context.page-type';
import { useFeatureToggle } from '@hotelplan/libs.feature-toggle';
import { getRouteByPageTypeLocale } from '@hotelplan/libs.router-config';
import { getTargetPageData } from '@hotelplan/libs.router-link-utils';
import GreetingsSkeleton from 'components/domain/my-account/GreetingsSkeleton';
import { FeatureList } from 'config/pageConfig.constants';
import { useGetAccountPersonalDataQuery } from 'graphql/my-account/GetAccount.generated';
import {
  MenuItemsWrapper,
  MenuLogoutLink,
  MyAccountHeaderMenuWrapper,
} from './MyAccountHeader.styles';

const menuPagesWithLabels = {
  [PageType.MyaccountMybookings]: 'myaccount:myBooking',
  [PageType.Wishlist]: 'myaccount:myWishlist',
  [PageType.MyaccountMyhistory]: 'myaccount:myHistory',
  [PageType.MyaccountMyprofile]: 'myaccount:myAccount',
};

const MY_ACCOUNT_PAGES: Array<PageType> = [
  PageType.MyaccountMybookings,
  PageType.MyaccountBookingDetails,
  PageType.MyaccountMyprofile,
  PageType.MyaccountMyhistory,
];

function getMenuItems(
  locale: string
): Array<{ label: string; routerProps: IRouterLinkProps }> {
  return Object.keys(menuPagesWithLabels).reduce((acc, pageType: PageType) => {
    const route = getRouteByPageTypeLocale(pageType, locale);

    acc.push({
      label: menuPagesWithLabels[pageType],
      routerProps: getTargetPageData({
        targetPageType: route.pageType,
        uri: route.builder({}),
      }),
    });

    return acc;
  }, []);
}

interface IMyAccountHeaderMenuProps {
  isOpened: boolean;
  onMenuItemClick?(): void;
}

export default function MyAccountHeaderMenu({
  isOpened,
  onMenuItemClick,
}: IMyAccountHeaderMenuProps): React.ReactElement {
  const { push } = useRouter();
  const pageType = usePageType<PageType>();
  const [t, { language }] = useTranslation(['common', 'myaccount']);
  const { logout } = useAuthentication();
  const scrollElementRef = useRef<HTMLDivElement>(null);
  const { isEnabled } = useFeatureToggle();
  const isMeinKontoEnabled = isEnabled(FeatureList.MEIN_KONTO);

  const { data, loading } = useGetAccountPersonalDataQuery({
    skip: !isOpened,
  });

  const { firstName, lastName } = data?.account.personalData || {};
  const menuItems = getMenuItems(language);

  async function logoutHandler() {
    if (isMeinKontoEnabled) {
      push(SSR_MY_ACCOUNT_LOGOUT_ENDPOINT_PATH);
    } else {
      await logout();

      if (MY_ACCOUNT_PAGES.includes(pageType)) {
        window.location.pathname = '/';
        return;
      }

      window.location.reload();
    }
  }

  useScrollElement(scrollElementRef);

  return (
    <MyAccountHeaderMenuWrapper ref={scrollElementRef}>
      <Greetings
        greetingsTextTranslation={t('common:greetings')}
        firstName={firstName}
        lastName={lastName}
        loading={loading}
        placeholder={<GreetingsSkeleton />}
      />
      <MenuItemsWrapper>
        {menuItems.map(({ label, routerProps }, index) => (
          <RouterLink {...routerProps} key={`${label}-${index}`} passHref>
            <Link className="menu-item" onClick={onMenuItemClick}>
              {t(label)}
            </Link>
          </RouterLink>
        ))}
      </MenuItemsWrapper>
      <MenuLogoutLink
        onClick={logoutHandler}
        variant={'linkBtn'}
        icon={{ name: 'logout' }}
      >
        {t('myaccount:logout')}
      </MenuLogoutLink>
    </MyAccountHeaderMenuWrapper>
  );
}
