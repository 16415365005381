import React from 'react';
import type { CSSObject } from 'styled-components';
import { RouterLink } from '@hotelplan/components.common.router-link';
import { getTargetPageData } from '@hotelplan/libs.router-link-utils';
import {
  NavigationTarget,
  trackNavigationElementOpen,
} from '@hotelplan/libs.tracking';
import WishlistIcon from 'components/domain/wishlist/WishlistIcon';
import { useGetWishlistComponentsQuery } from 'graphql/wishlist/GetWishlistComponents.generated';

interface IWishListIconContainer {
  className?: string;
  iconCss?: CSSObject;
  counterCss?: CSSObject;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

const WishlistIconContainer: React.FC<IWishListIconContainer> = ({
  className,
  iconCss,
  counterCss,
}) => {
  const { data } = useGetWishlistComponentsQuery({
    ssr: false,
  });

  const routerLinkProps = data ? getTargetPageData(data.wishlist.url) : null;

  const handleWishlistIconClick = () => {
    trackNavigationElementOpen(NavigationTarget.WISHLIST);
  };

  const wishListIconProps = {
    iconCss: iconCss,
    className,
    withTooltip: false,
    counterCss: counterCss,
    wishlistProductCount: data?.wishlist.productsAmount,
    onClick: handleWishlistIconClick,
  };

  return routerLinkProps ? (
    <RouterLink {...routerLinkProps} shallow={false} passHref>
      <WishlistIcon {...wishListIconProps} />
    </RouterLink>
  ) : (
    <WishlistIcon {...wishListIconProps} />
  );
};

export default WishlistIconContainer;
