import { useTranslation } from 'next-i18next';
import React from 'react';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { useToggleState } from '@hotelplan/libs.hooks-react';
import { MyAccountHeaderButton } from 'components/domain/my-account/header-section';
import Tooltip from 'components/domain/site-tool-buttons/Tooltip';
import { MyAccountHeaderSectionWrapper } from './Header.styled';

interface IMyAccountHeaderSectionProps {
  isAuthenticated: boolean;
}

export default function MyAccountHeaderSection({
  isAuthenticated,
}: IMyAccountHeaderSectionProps): React.ReactElement {
  const [isMenuOpened, , close, toggle] = useToggleState(false);
  const [t] = useTranslation('common');
  const { mobile } = useDeviceType();

  return mobile ? (
    <MyAccountHeaderSectionWrapper>
      <MyAccountHeaderButton
        isAuthenticated={isAuthenticated}
        isMenuOpened={isMenuOpened}
        closeMenu={close}
        toggleMenu={toggle}
      />
    </MyAccountHeaderSectionWrapper>
  ) : (
    <Tooltip text={t('myaccount.header.tooltip')}>
      <MyAccountHeaderSectionWrapper>
        <MyAccountHeaderButton
          isAuthenticated={isAuthenticated}
          isMenuOpened={isMenuOpened}
          closeMenu={close}
          toggleMenu={toggle}
        />
      </MyAccountHeaderSectionWrapper>
    </Tooltip>
  );
}
