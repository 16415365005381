import { ApolloClient, ApolloCache } from '@apollo/client';
import { languageDefault } from '@hotelplan/libs.language';
import {
  WishlistItemFragment,
  WishlistItemFragmentDoc,
} from 'graphql/wishlist/WishlistItemFragment.generated';
import { WishlistViewType } from './Wishlist.constants';

export const wishlistViewTypeMap: { [view: string]: WishlistViewType } = {
  listenansicht: WishlistViewType.List,
  vergleichen: WishlistViewType.Compare,
  'vue-liste': WishlistViewType.List,
  comparer: WishlistViewType.Compare,
};

export function removeWishlistActiveCache<
  TCache extends Record<string, any> = object
>(cache: ApolloCache<TCache>): void {
  const cacheData: TCache = (cache as any).data.data;
  const wishlistKeys: string[] = Object.keys(cacheData).filter(
    cacheKey =>
      /wishlist/i.test(cacheKey) && /(\.active\(|\.overview)/i.test(cacheKey)
  );
  wishlistKeys.forEach(cacheKey => {
    delete cacheData[cacheKey];
  });
}

export function updateWishlistItemCache(
  client: ApolloClient<object>,
  offerId: string | undefined,
  inWishlist: boolean
): void {
  if (!offerId) return;

  const fragment = client.readFragment<WishlistItemFragment>({
    fragment: WishlistItemFragmentDoc,
    id: `WishlistItem:${offerId}`,
  });

  if (fragment?.inWishlist === inWishlist) return;

  try {
    client.writeFragment<WishlistItemFragment>({
      fragment: WishlistItemFragmentDoc,
      id: `WishlistItem:${offerId}`,
      data: {
        __typename: `WishlistItem`,
        id: offerId,
        inWishlist,
      },
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Could not update wishlist offer`, e);
  }
}

export function getWishlistUrl(
  type: WishlistViewType,
  language: string
): { view: string; uri: string } {
  if (type === WishlistViewType.List) {
    return {
      view: language === languageDefault ? 'listenansicht' : 'vue-liste',
      uri:
        language === languageDefault
          ? '/merkliste/listenansicht'
          : '/liste-de-favoris/vue-liste',
    };
  }

  return {
    view: language === languageDefault ? 'vergleichen' : 'comparer',
    uri:
      language === languageDefault
        ? '/merkliste/vergleichen'
        : '/liste-de-favoris/comparer',
  };
}
