import noop from 'lodash/noop';
import { useTranslation } from 'next-i18next';
import React, { forwardRef, useEffect, useRef } from 'react';
import type { CSSObject } from 'styled-components';
import styled from 'styled-components';
import { Link } from '@hotelplan/components.common.link';
import {
  setTooltipPosition,
  Tooltip,
} from '@hotelplan/components.common.tooltip';
import { BsButtonWithIcon } from '@hotelplan/core.fusion.bs-button-with-icon';
import { E_BUTTON_TYPE } from '@hotelplan/style.hotelplan-style';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import FavIcon from 'components/domain/fav-icon/FavIcon';
import { useWishlistOnlyOneSelectedOfferContext } from 'components/domain/wishlist/WishlistOnlyOneSelectedOfferContext';

interface IWishListIconProps {
  id?: string;
  loading?: boolean;
  isActive?: boolean;
  label?: string;
  withLabel?: boolean;
  withTooltip?: boolean;
  counterCss?: CSSObject;
  wishlistProductCount?: number;
  href?: string | undefined;
  onClick?(e: React.MouseEvent<HTMLElement>): void;
  className?: string;
}

const labelStyles: CSSObject = {
  fontSize: '14px',
  fontWeight: 'normal',
};

const WishlistProductWrapper = styled.span.attrs({
  className: 'counter',
  'data-id': `wishlist-product-count-id`,
})<{ counterStyles?: CSSObject }>(
  ({ theme: { shadows } }) =>
    sx2CssThemeFn({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '20px',
      height: '20px',
      backgroundColor: 'primary',
      color: 'white',
      borderRadius: '50%',
      fontSize: '12px',
      boxShadow: shadows.large,
      position: 'absolute',
      top: '2px',
      left: 28,
      lineHeight: '18px',
      transform: 'translate(-50%, -50%)',
    }),
  props => props.counterStyles
);

const WishlistIconLabelWrapper = styled.span.attrs({
  className: 'label',
  'data-id': `wishlist-icon-label`,
})(labelStyles);

const ICON_WIDTH_MD = 28;
const ICON_HALF_WIDTH_MD = 10;

type FavButtonProps = Pick<IWishListIconProps, 'href'>;

const FavButton = styled(BsButtonWithIcon).attrs<FavButtonProps>(
  ({ href }) => ({
    'data-id': `fav-btn`,
    variant: E_BUTTON_TYPE.ICON_BUTTON,
    href,
  })
)(
  sx2CssThemeFn({
    padding: '4px',
    fontSize: '0px',
    letterSpacing: 'initial',
    lineHeight: 1.3,
    svg: {
      margin: 0,
    },
    position: 'relative',
    cursor: 'pointer',
    ':hover': {
      '.tooltip, .tooltipArrow': {
        visibility: 'visible',
      },
      '.icon': {
        color: 'primary',
      },
    },
  })
);

const FavButtonLink = FavButton.withComponent(Link);

const WishlistIcon = forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  IWishListIconProps
>((props, ref) => {
  const {
    id,
    loading,
    wishlistProductCount,
    isActive = false,
    label,
    withLabel = !!props.label,
    withTooltip = true,
    counterCss,
    href,
    onClick = noop,
    className,
  } = props;

  const [t] = useTranslation('wishlist');
  const tooltipElRef = useRef<HTMLElement>(null);
  const { selectedId } = useWishlistOnlyOneSelectedOfferContext();

  useEffect(
    function adjustTooltipPosition() {
      if (!tooltipElRef.current) return;

      setTooltipPosition(tooltipElRef.current as HTMLElement, {
        tooltipTargetObjectWidth: ICON_WIDTH_MD,
        tooltipTargetObjectHalfWidth: ICON_HALF_WIDTH_MD,
      });
    },
    [tooltipElRef]
  );

  const FavControl = ((href
    ? FavButtonLink
    : FavButton) as unknown) as React.FC<
    {
      className: string;
      onClick?(): void;
      href: string;
      children?: React.ReactNode;
    } & React.RefAttributes<HTMLButtonElement | HTMLAnchorElement>
  >;

  return (
    <FavControl
      className={`wishlist-icon ${className || ''} ${
        isActive ? 'active' : 'not-active'
      }`}
      onClick={onClick}
      href={href}
      ref={ref}
    >
      <FavIcon id={id} active={isActive} loading={loading} />
      {withTooltip && (
        <Tooltip
          withAutoPositioning
          tooltipElRef={tooltipElRef}
          testId="wishlist-icon-tooltip"
        >
          {isActive
            ? t('wishlist:icon.active.title')
            : selectedId
            ? t('wishlist:icon.replace.title')
            : t('wishlist:icon.notActive.title')}
        </Tooltip>
      )}
      {wishlistProductCount && wishlistProductCount > 0 && (
        <WishlistProductWrapper counterStyles={counterCss}>
          {wishlistProductCount}
        </WishlistProductWrapper>
      )}
      {withLabel && (
        <WishlistIconLabelWrapper>
          {label || t('wishlist:icon.label')}
        </WishlistIconLabelWrapper>
      )}
    </FavControl>
  );
});

if (process.env.NODE_ENV !== 'production') {
  WishlistIcon.displayName = 'WishlistIcon';
}

export default WishlistIcon;
