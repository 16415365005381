import { useApolloClient } from '@apollo/client';
import { useMemo } from 'react';
import { updateWishlistItemCache } from './Wishlist.utils';

export interface IWishlistOfferManaging {
  update(offerId: string, next: boolean): void;
  select(offerId: string): void;
  deselect(offerId: string): void;
}

export function useWishlistCacheManage(): IWishlistOfferManaging {
  const client = useApolloClient();

  return useMemo(
    () => ({
      update(offerId, next) {
        updateWishlistItemCache(client, offerId, next);
      },
      select(offerId) {
        updateWishlistItemCache(client, offerId, true);
      },
      deselect(offerId) {
        updateWishlistItemCache(client, offerId, false);
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [client]
  );
}
