import React from 'react';
import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';

type TProps = React.PropsWithChildren<{ text: string }>;

const TooltipWrapper = styled.div(
  ({ theme, theme: { colors, radii, fontSizes } }) =>
    sx2CssThemeFn({
      display: [null, 'flex'],
      alignItems: [null, 'center'],
      position: 'relative',
      '.tooltip': {
        whiteSpace: 'nowrap',
        transform: 'translateX(-50%)',
        background: 'white',
        borderRadius: radii.default,
        position: 'absolute',
        display: 'block',
        visibility: 'hidden',
        border: `1px solid ${colors.borderColor}`,
        padding: '10px 12px',
        fontSize: fontSizes[1],
        fontWeight: 'bold',
        bottom: '-64%',
        left: '15px',
        zIndex: 100,
        '@media screen and (max-width: 1040px)': {
          padding: '10px 6px',
        },
      },
      ':hover .tooltip': {
        visibility: [null, `visible`],
        boxShadow: '8px 8px 8px 0px rgba(0,0,0,0.2)',
        display: 'block',
        backgroundColor: 'white',
        position: 'absolute',
        '&:after': {
          content: "''",
          display: 'block',
          position: 'absolute',
          width: '16px',
          height: '16px',
          backgroundColor: 'white',
          transform: 'rotate(45deg) matrix(1, 0.2, 0.2, 1, 0, 0)',
          top: '-6.5px',
          left: 'calc(50% - 8px)',
          background: 'white',
          zIndex: '999999',
        },
        '&:before': {
          content: "''",
          display: 'block',
          position: 'absolute',
          width: '16px',
          height: '16px',
          backgroundColor: colors.borderColor,
          transform: 'rotate(45deg) matrix(1, 0.2, 0.2, 1, 0, 0)',
          top: '-8px',
          borderRadius: ' 0 0 100% 0',
          left: 'calc(50% - 8px)',
        },
      },
      ...(theme.customTooltip?.header || {}),
    })
);

const Tooltip: React.FC<TProps> = ({ children, text }: TProps) => {
  return (
    <TooltipWrapper>
      {children}
      <div className="tooltip">{text}</div>
    </TooltipWrapper>
  );
};

export default Tooltip;
