import React from 'react';
import styled from 'styled-components';
import {
  PlaceholderLoader,
  IPlaceholderLoaderProps,
} from '@hotelplan/components.common.placeholder-loader';

type Size = 'xs' | 'sm' | 'md' | 'lg' | 'xlg' | 'xxlg';

export interface IFavIconProps extends Omit<IPlaceholderLoaderProps, 'uid'> {
  className?: string;
  active?: boolean;
  loading?: boolean;
  size?: Size;
}

const DEFAULT_VARIANT_VALUE: Size = 'md';

const outerPath =
  'M1.03 4.21l.1-.24A5.95 5.95 0 019.36 1.2l.27.16c.44.27.84.6 1.2.96L12 3.47l1.17-1.13a5.89 5.89 0 018.45-.1l.18.18c.35.38.66.8.92 1.28l.15.27c1.15 2.4.7 5.32-1.13 7.2l-9.37 10.17a.5.5 0 01-.74 0L2.1 11A6.44 6.44 0 011.03 4.2z';
const innerPath =
  'm9.08-1.17A4.95 4.95 0 002.04 4.4l-.1.22C1.08 6.6 1.5 8.96 2.99 10.5l9 9.76 9.2-9.95a5.43 5.43 0 00.88-5.67l-.1-.22-.13-.25a5.37 5.37 0 00-.77-1.07 4.9 4.9 0 00-7.2-.05l-1.52 1.47a.5.5 0 01-.7 0l-1.54-1.48z';

const fullPath = outerPath;
const emptyPath = outerPath + innerPath;

interface IFavIconSVGProps extends IPlaceholderLoaderProps {
  active?: boolean;
  size?: Size;
}

const FavIconSVG = styled(PlaceholderLoader).attrs(({ theme: { colors } }) => ({
  fgColor: `${colors.primary}`,
}))<IFavIconSVGProps>(
  ({ size, theme }) => (size ? theme.icons[size] : {}),
  ({ active, theme: { colors } }) => ({
    fill: active ? colors.primary : 'currentColor',
    display: 'inline-block',
  })
);

const FavIcon: React.FC<IFavIconProps> = props => {
  const {
    id,
    className = '',
    loading = false,
    active,
    size = DEFAULT_VARIANT_VALUE,
    ...svgProps
  } = props;

  const empty = !active && !loading;

  return (
    <FavIconSVG
      uid={id || 'fav-icon'}
      className={`icon ${className} ${active ? 'active' : ''}`}
      active={active}
      size={size}
      viewBox="0 0 24 22"
      speed={1}
      loading={loading}
      data-id="fav"
      {...svgProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d={empty ? emptyPath : fullPath}
      />
    </FavIconSVG>
  );
};

export default FavIcon;
