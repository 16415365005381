import { useTranslation } from 'next-i18next';
import React from 'react';
import { useAuthentication } from '@hotelplan/components.common.auth';
import { AuthChannelType } from '@hotelplan/graphql.local-types';
import MyAccountButton from 'components/domain/site-tool-buttons/MyAccountButton';
import Tooltip from 'components/domain/site-tool-buttons/Tooltip';

interface IMyAccountSiteToolProps {
  onClick?(): void;
}

const MyAccountSiteTool: React.FC<IMyAccountSiteToolProps> = props => {
  const { onClick } = props;
  const { channelType } = useAuthentication();
  const [t] = useTranslation('auth');

  if (channelType === AuthChannelType.B2B) return null;

  return (
    <Tooltip text={t('myAccount.tooltip')}>
      <MyAccountButton onClick={onClick} />
    </Tooltip>
  );
};

export default MyAccountSiteTool;
