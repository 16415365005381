import React, { createContext, useCallback, useContext, useState } from 'react';
import { WishlistItemFragment } from 'graphql/wishlist/WishlistItemFragment.generated';
import {
  IWishlistOfferManaging,
  useWishlistCacheManage,
} from './useWishlistCacheManage';

interface IWishlistOfferLike {
  id: string;
  wishlistItem?: WishlistItemFragment | null;
}

export interface IWishlistOnlyOneSelectedOfferContext<
  T extends IWishlistOfferLike
> extends IWishlistOfferManaging {
  provided: boolean;
  selectedId?: string;
  addOffers(offers: T[]): void;
  reset(): void;
}

const noop = (name: string) => () => {
  // eslint-disable-next-line no-console
  console.warn(`Calling noop '${name}'`);
};

const WishlistOnlyOneSelectedOfferContext = createContext<
  IWishlistOnlyOneSelectedOfferContext<any>
>({
  provided: false,
  addOffers: noop(`addOffers`),
  reset: noop(`reset`),
  select: noop(`select`),
  deselect: noop(`deselect`),
  update: noop(`update`),
});

export const WishlistOnlyOneSelectedOfferProvider: React.FC<{
  children?: React.ReactNode;
}> = props => {
  const { children } = props;

  const cache = useWishlistCacheManage();

  const [selectedId, setSelectedId] = useState<string | undefined>(undefined);

  const reset = useCallback(() => {
    setSelectedId(undefined);
  }, []);

  const addOffers = useCallback((offers: IWishlistOfferLike[]) => {
    const foundSelectedId = offers.find(offer => offer.wishlistItem?.inWishlist)
      ?.id;

    if (foundSelectedId) {
      select(foundSelectedId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const update = useCallback(
    (offerId: string, next: boolean) => {
      if (next && selectedId && selectedId !== offerId) {
        cache.deselect(selectedId);
      }
      setSelectedId(prevId =>
        next ? offerId : prevId === offerId ? undefined : prevId
      );
      cache.update(offerId, next);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedId, cache]
  );

  const select = useCallback(
    (offerId: string): void => {
      update(offerId, true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [update]
  );

  const deselect = useCallback(
    (offerId: string): void => {
      update(offerId, false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [update]
  );

  return (
    <WishlistOnlyOneSelectedOfferContext.Provider
      value={{
        provided: true,
        selectedId,
        update,
        select,
        deselect,
        reset,
        addOffers,
      }}
    >
      {children}
    </WishlistOnlyOneSelectedOfferContext.Provider>
  );
};

export function useWishlistOnlyOneSelectedOfferContext<
  T extends IWishlistOfferLike
>() {
  return useContext<IWishlistOnlyOneSelectedOfferContext<T>>(
    WishlistOnlyOneSelectedOfferContext as React.Context<
      IWishlistOnlyOneSelectedOfferContext<T>
    >
  );
}
