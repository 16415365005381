import { useTranslation } from 'next-i18next';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useAuthentication } from '@hotelplan/components.common.auth';
import { Link } from '@hotelplan/components.common.link';
import { RouterLink } from '@hotelplan/components.common.router-link';
import { AuthChannelType } from '@hotelplan/graphql.local-types';
import { PageType } from '@hotelplan/graphql.types';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { getRouteByPageTypeLocale } from '@hotelplan/libs.router-config';
import { getTargetPageData } from '@hotelplan/libs.router-link-utils';
import { siteToolsButtonCss } from 'components/domain/site-tool-buttons/SiteTools.styles';

const PersonLink = styled(Link).attrs({
  icon: { name: 'person' },
  testId: `person-link`,
})<{ active: boolean }>(
  ({ theme }) => theme.buttons.iconBtn,
  siteToolsButtonCss,
  ({ active, theme: { colors } }) => ({
    color: active ? colors.primary : undefined,
  })
);

export interface IMyAccountButtonProps {
  onClick?(): void;
}

const MyAccountButton: React.FC<IMyAccountButtonProps> = props => {
  const [t, { language }] = useTranslation('common');
  const { mobile } = useDeviceType();
  const { channelType } = useAuthentication();
  const isAuthenticated = channelType !== AuthChannelType.B2CAnonymous;
  const { onClick } = props;

  const routerProps = useMemo(() => {
    const route = getRouteByPageTypeLocale(
      isAuthenticated
        ? mobile
          ? PageType.MyaccountOverview
          : PageType.MyaccountMybookings
        : PageType.MyaccountLogin,
      language
    );

    return route
      ? getTargetPageData({
          targetPageType: route.pageType,
          uri: route.builder({}),
        })
      : null;
  }, [isAuthenticated, language, mobile]);

  return isAuthenticated && mobile ? (
    <PersonLink active={isAuthenticated} onClick={onClick} />
  ) : (
    <RouterLink {...routerProps} passHref={true}>
      <PersonLink active={isAuthenticated} onClick={onClick}>
        {isAuthenticated ? t('account.button') : t('login.button')}
      </PersonLink>
    </RouterLink>
  );
};

export default MyAccountButton;
