import { useTranslation } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import { siteToolsButtonCss } from 'components/domain/site-tool-buttons/SiteTools.styles';
import Tooltip from 'components/domain/site-tool-buttons/Tooltip';
import WishlistIconContainer from 'components/domain/wishlist/WishlistIconContainer';

const SiteToolWishlistIcon = styled(WishlistIconContainer)(siteToolsButtonCss);

const WishlistIconSiteTool: React.FC = () => {
  const [t] = useTranslation('wishlist');

  return (
    <Tooltip text={t('header.icon.tooltip')}>
      <SiteToolWishlistIcon />
    </Tooltip>
  );
};

export default WishlistIconSiteTool;
